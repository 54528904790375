<!--
 * @Author: 月魂
 * @Date: 2021-03-24 09:43:47
 * @LastEditTime: 2021-06-24 16:48:25
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \stoms-mp\src\views\CardDetail.vue
-->
<template>
  <div class="box">
    <van-nav-bar
      title="详情"
      fixed
      placeholder
    >
      <template #right>
        <van-icon name="wap-home-o" size="18" @click="jumpHome" />
      </template>
    </van-nav-bar>
    <div>
      <div class="card">
        <img :src="data.image_url" class="cardImg">
      </div>
      <div class="content">
        <div class="item">
          <van-row>
            <van-col span="6">名称</van-col>
            <van-col span="18">{{ data.name }}</van-col>
          </van-row>
          <van-row v-show="data.originalPrice">
            <van-col span="6">原价</van-col>
            <van-col span="18"><span style="text-decoration: line-through; font-size: 14px">￥{{ data.originalPrice }}</span></van-col>
          </van-row>
           <van-row>
            <van-col span="6">金额</van-col>
            <van-col span="18"><span style="color: #D9001B">￥{{ data.price }}</span></van-col>
          </van-row>
          <van-row v-if="data.type == 1">
            <van-col span="6">总次数</van-col>
            <van-col span="18">{{ data.total_times }} 次</van-col>
          </van-row>
          <van-row v-else>
            <van-col span="6">有效期</van-col>
            <van-col span="18">{{ data.period }} 天</van-col>
          </van-row>
        </div>
      </div>
      <div class="rights">
        <div class="title">会员权益</div>
        <div v-html="data.intruduction"></div>
      </div>
    </div>
    <van-goods-action safe-area-inset-bottom>
      <van-goods-action-button
        v-if="data.status === 0"
        type="danger"
        text="敬请期待"
        disabled
      />
      <van-goods-action-button
        v-else
        type="danger"
        text="立即购买"
        @click="handleBuy"
      />
    </van-goods-action>
  </div>
</template>

<script>
import Vue from 'vue';
import wx from 'weixin-js-sdk'
import { getCardDetail, getWxParams } from '@/api/card'
import { NavBar, GoodsAction, GoodsActionIcon, GoodsActionButton, Icon, Row, Col } from 'vant';
Vue.use(NavBar).use(GoodsAction).use(GoodsActionIcon).use(GoodsActionButton).use(Icon).use(Row).use(Col);

export default {
  name: 'CardDetail',
  data() {
    return {
      data: {}
    }
  },
  mounted() {
    getCardDetail(this.$route.params.id).then(res => {
      if (res.code !== 0) return
      this.data = res.data
    })
    let wechaturl = window.location.href.split('#')[0];//单页应用就获取#前面的东西
    let link = encodeURIComponent(wechaturl);
    getWxParams(link).then(res => {
      wx.ready(() => {
        let shareData = {
          title: this.data.name,
          desc: `售价${this.data.price}元, 速来抢购吧`,
          link: location.href, //必须是js安全域名下的地址(分享出去的没有图片显示请检查这里的link参数)
          imgUrl: this.data.image_url,//随意地址的图片都行，最好是jpg的，经测试无图片大小约束
          success: function () {
            console.log(1, 'success');
          },
          cancel: function () {
            console.log(2, 'cancel');
          }
        }
        wx.updateAppMessageShareData(shareData) //分享给好友
        wx.updateTimelineShareData(shareData) //分享到朋友圈
      })
      wx.error(function (res) {
        console.log(res, 'err');
      })
      wx.config({
        debug: false,
        appId: res.data.appId, // 必填，公众号的唯一标识
        timestamp: res.data.timestamp, // 必填，生成签名的时间戳，精确到秒(后端返回)
        nonceStr: res.data.nonceStr, // 必填，生成签名的随机串(后端返回)
        signature: res.data.signature, // 必填，签名(后端返回)
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
      })
    })
  },
  methods: {
    jumpHome() {
      this.$router.replace('/')
    },
    handleBuy() {
      this.$router.replace(`/card/order/${this.$route.params.id}`);
    }
  }
}

</script>

<style lang="less" scoped>
.box {
  min-height: 100%;
}
.card {
  box-sizing: border-box;
  width: 355px;
  border-radius: 20px;
  overflow: hidden;
  margin:  10px auto;
  .cardImg {
    display: block;
    width: 100%;
    height: 150px;
  }
}
.content {
  background-color: #fff;
  padding: 10px;
  font-size: 14px;
  color: #555;
  line-height: 2;
  margin-bottom: 10px;
}
.rights {
  padding: 10px 10px 50px;
  .title {
    font-size: 14px;
    margin-bottom: 10px;
    color: #333;
  }
  .item {
    display: flex;
    justify-content: flex-start;
    padding: 10px 0;
    .left {
      margin-right: 10px;
      img {
        width: 16px;
        height: 16px;
      }
    }
    .right {
      .text {
        font-size: 14px;
        color: #7f7f7f;
      }
      .des {
        font-size: 12px;
        padding: 10px 0;
        color: #7f7f7f;
      }
    }
  }
  .dashed {
    border: 0.5px dashed #d7d7d7;
  }
}
</style>